
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/AdminMenu.vue";
import { Modules } from "@/store/enums/AdminStoreEnums";

export default defineComponent({
  name: "kt-admin-aside-primary",
  components: {
    KTQuickLinksMenu,
    KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const currentUser = computed(() => {
      return store.getters[Modules.AUTH + "currentUser"];
    });

    return {
      currentUser,
      publicPath: process.env.BASE_URL,
    };
  },
});
