const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: { name: "adminDashboard" },
        fontIcon: "fas fa-tachometer-alt",
        onlyAdmin: false,
      },
      {
        heading: "Events",
        route: { name: "adminEvents" },
        fontIcon: "fas fa-calendar-alt",
        onlyAdmin: false,
      },
      {
        heading: "Users",
        route: { name: "adminUsers" },
        fontIcon: "fas fa-users",
        onlyAdmin: false,
      },
      {
        heading: "Moderators",
        route: { name: "adminModerators" },
        fontIcon: "fas fa-users",
        onlyAdmin: true,
      },
      {
        heading: "World Sessions",
        route: { name: "worldSessions" },
        fontIcon: "fas fa-user-clock",
        onlyAdmin: false,
      },
      {
        heading: "Event Moderation",
        route: { name: "adminEventModeration" },
        fontIcon: "fas fa-filter",
        onlyAdmin: false,
      },{
        heading: "Campaigns Reg",
        route: { name: "campaignsReg" },
        fontIcon: "fas fa-filter",
        onlyAdmin: true,
      },
    ],
  },
  {
    heading: "configuration",
    pages: [
      {
        heading: "Configurations",
        route: { name: "adminConfigurations" },
        fontIcon: "fas fa-cog",
        onlyAdmin: true,
      },
      {
        sectionTitle: "Masters",
        fontIcon: "fas fa-database",
        sub: [
          {
            heading: "Countries",
            route: { name: "adminCountries" },
            onlyAdmin: true,
          },
          {
            heading: "Professions",
            route: { name: "adminProfessions" },
            onlyAdmin: true,
          },
          {
            heading: "Timezones",
            route: { name: "adminTimezones" },
            onlyAdmin: true,
          },
          {
            heading: "Event Categories",
            route: { name: "adminEventCategories" },
            onlyAdmin: true,
          },
          {
            heading: "Event Environments",
            route: { name: "adminEventEnvironments" },
            onlyAdmin: true,
          },
          {
            heading: "Spaces",
            route: { name: "adminSpaces" },
            onlyAdmin: true,
          },
          {
            heading: "Zones",
            route: { name: "adminZones" },
            onlyAdmin: true,
          },
          {
            heading: "Resources",
            route: { name: "adminResources" },
            onlyAdmin: false,
          },
          {
            heading: "Portals",
            route: { name: "adminPortals" },
            onlyAdmin: true,
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
